body {
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Segoe UI Web (West European), Segoe, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.Toastify__toast-container {
  width: auto;
  max-width: 500px;
  min-width: 300px;
  z-index: 9999999;
}

.Toastify__progress-bar {
  height: 2px;
}

.Toastify__toast--error {
 
  .Toastify__progress-bar {
    background-color: #a80000;
  }
}

.Toastify__toast--success {
  .Toastify__progress-bar {
    background-color: #107c10;
  }
}

.Toastify__toast--info {
  .Toastify__progress-bar {
    background-color: #605e5c;
  }
}

// .Toastify__toast-body {
//   padding: 0 !important; /* Remove padding */
// }